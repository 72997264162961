<template>
  <section class="box-height perfromanceTable" style="padding: 0 15px">
    <div class="scollDom" style="border: 1px #e5e5e5 solid; background: #fff">
      <el-col :span="24" class="customTag" style="padding: 0 15px">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane name="first">
              <span class="tagW" slot="label">团队总业绩</span>
            </el-tab-pane>
            <el-tab-pane name="second">
              <span class="tagW" slot="label">团队的客户业绩</span>
            </el-tab-pane>
            <el-tab-pane name="three">
              <span class="tagW" slot="label">客户的团队业绩</span>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      <el-form
        :model="filter"
        ref="filter"
        style="background-color: #fff"
        label-width="100px"
      >
        <el-row>
          <div class="clearfix sechContent" style="padding: 15px 15px 0">
            <el-form-item
              v-if="!isAnd&&activeName=='first'"
              label="时间："
              prop="time"
              class="fl"
              style="margin-bottom: 20px"
            >
              <div style="display: flex">
                <el-select
                  v-model="filter.timeType"
                  placeholder="请选择"
                  filterable
                  @change="timeTypeChage"
                  style="width: 80px"
                  class="dinput"
                >
                  <el-option
                    v-for="(item, index) in timeTypeList"
                    :key="'time_' + index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <el-date-picker
                  v-model="filter.time"
                  v-show="
                    filter.timeType !== '按月' && filter.timeType !== '按季'
                  "
                  type="daterange"
                  @change="loadInfo"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  style="width: 240px; padding: 2px 10px; margin-top: 2px"
                  class="dinputr"
                ></el-date-picker>
                <el-date-picker
                  v-show="filter.timeType == '按月'"
                  v-model="filter.time"
                  type="monthrange"
                  @change="loadInfo"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  style="width: 240px; padding: 2px 10px; margin-top: 2px"
                  class="dinputr"
                ></el-date-picker>
                <quarter
                  class="dinputr"
                  v-if="filter.timeType == '按季'"
                  @getDate="getDate"
                ></quarter>
                <el-select
                  v-model="filter.dateSearchType"
                  placeholder="请选择"
                  filterable
                  @change="loadInfo"
                  class="dinput"
                >
                  <el-option label="按业绩归属时间" value="ATTRIBUTION">
                  </el-option>
                  <el-option label="按实际时间" value="ACTUAL"> </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item
              v-if="isAnd&&activeName=='first'"
              prop="time"
              class="fl"
              label-width="0"
              style="margin-bottom: 20px"
            >
              <div style="display: flex">
                <p>开票时间</p>
                <el-date-picker
                  v-model="filter.makeInvoicetime"
                  type="daterange"
                  @change="loadInfo"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  style="width: 240px; padding: 2px 10px"
                  class="dinputr"
                ></el-date-picker>
                <span>且</span>
                <p>回款时间</p>
                <el-date-picker
                  v-model="filter.accounttime"
                  type="daterange"
                  @change="loadInfo"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  style="width: 240px; padding: 2px 10px"
                  class="dinputr"
                ></el-date-picker>
              </div>
            </el-form-item>
            <el-form-item
              v-if="activeName!=='first'"
              label="时间："
              prop="time"
              class="fl"
              style="margin-bottom: 20px"
            >
              <div style="display: flex">
                <el-select
                  v-model="filter.timeType"
                  placeholder="请选择"
                  filterable
                  @change="timeTypeChage"
                  style="width: 80px"
                  class="dinput"
                >
                  <el-option
                    v-for="(item, index) in timeSecTypeList"
                    :key="'time_' + index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <el-date-picker
                  v-model="filter.time"
                  v-show="
                    filter.timeType !== '按月' && filter.timeType !== '按季'&& filter.timeType !== '按年'
                  "
                  type="daterange"
                  @change="loadInfo"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  style="width: 240px; padding: 2px 10px; margin-top: 2px"
                  class="dinputr"
                ></el-date-picker>
                <el-date-picker
                  v-show="filter.timeType == '按年'"
                  v-model="yearVal"
                  type="year"
                  @change="getYear"
                  value-format="yyyy"
                  style="width: 240px;"
                  class="dinputr"
                ></el-date-picker>
                <el-date-picker
                  v-show="filter.timeType == '按月'"
                  v-model="filter.time"
                  type="monthrange"
                  @change="loadInfo"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  style="width: 240px; padding: 2px 10px; margin-top: 2px"
                  class="dinputr"
                ></el-date-picker>
                <quarter
                  class="dinputr"
                  v-if="filter.timeType == '按季'"
                  @getDate="getDate"
                ></quarter>
                <el-select
                  v-model="filter.dateSearchType"
                  placeholder="请选择"
                  filterable
                  @change="loadInfo"
                  class="dinput"
                >
                  <el-option label="按业绩归属时间" value="ATTRIBUTION">
                  </el-option>
                  <el-option label="按实际时间" value="ACTUAL"> </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item
            v-if="activeName=='first'||activeName=='second'||activeName=='three'"
              prop="searchIdList"
              label="对象："
              class="fl"
              style="margin-left: 20px; margin-bottom: 20px"
            >
              <el-select
                v-model="filter.searchType"
                placeholder="请选择大类"
                filterable
                @change="changeOption"
                style="width: 90px"
                class="dinput"
              >
                <el-option
                  v-for="item in optionsList"
                  :key="item.type"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
              <el-cascader
                class="searchCascader dinputr"
                style="width: 240px"
                filterable
                clearable
                :options="options2"
                collapse-tags
                v-model="searchIdList"
                :show-all-levels="false"
                :props="props"
                placeholder="请选择小类"
                ref="dataRef"
                @change="getSearch"
                :filter-method="filterFun"
              >
              </el-cascader>
              <el-checkbox
                class="showCheckbox"
                style="width: 100px; margin-left: 15px"
                @change="isStop"
                v-model="filter.show"
                >停用账户</el-checkbox
              >
            </el-form-item>
            <el-form-item
            v-if="activeName!=='first'"
              prop="companyIds"
              label="客户名称："
              class="fl"
              style="margin-left: 20px;"
            >
              <el-select
                v-model="filter.companyIds"
                multiple
                filterable
                clearable
                collapse-tags
                @change="getSearch"
                placeholder="请选择客户名称"
                style="width: 240px"
                @focus="onDropdownInputFocus"
                ref='selectDropdown'
              >
              <el-option
                  v-for="i in filterComBaseList"
                  :key="i.id"
                  :label="i.name"
                  :value="i.id"
                ></el-option>
              </el-select>
              </el-form-item>
            <p
            v-if="activeName=='first'"
              class="tabHref"
              style="float: right; line-height: 32px"
              @click="(isAnd = !isAnd), loadInfo()"
            >
              {{ isAnd ? "常规查询>" : "'且'查询>" }}
            </p>
          </div>
          <el-col :span="24">
            <el-button
              style="margin-left: 15px"
              size="mini"
              @click="exportList"
              :loading="exportBtnLoading"
              >导出</el-button
            >
            <el-pagination
              :hide-on-single-page="filter.page.total > 10 ? false : true"
              v-if="filter.page.total > 0"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="filter.page.current"
              :page-size="filter.page.size"
              :pager-count="5"
              layout="total,prev, pager, next,sizes"
              :total="filter.page.total"
              :page-sizes="[10, 20, 50, 100]"
            ></el-pagination>
          </el-col>
        </el-row>
      </el-form>
      <div
       v-if="activeName=='first'"
        class="pd40"
        style="background-color: #fff; padding-top: 10px; padding-bottom: 20px"
      >
        <el-table
          :data="ListData"
          v-loading="listLoading"
          :header-cell-style="{
            'background-color': '#f5f7fa',
            'border-top': '1px #e5e5e5 solid',
          }"
          v-horizontal-scroll
          v-sticky="{ top: 0, parent: '.scollDom' }"
          class="tabBorder custor notableBorder"
          style="width: 100%; margin: 0px; border-top: 0"
        >
          <el-table-column
            v-if="!isAnd"
            prop="sourceName"
            min-width="150"
            label="对象"
          >
            <template #default="scope">
              <span
                v-if="scope.row.sourceType !== '顾问'"
                class="tabHref"
                @click="getDataThrough(scope.row)"
                >{{ scope.row.sourceName }}</span
              >
              <span v-else>{{ scope.row.sourceName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="isAnd"
            prop="objectName"
            min-width="150"
            label="对象"
          >
            <template #default="scope">
              <span>{{ scope.row.objectName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="isAnd"
            prop="amount"
            min-width="150"
            label="业绩额"
          >
            <template #default="scope">
              <span class="tabHref" @click="showDig(scope.row, '业绩额')">{{
                scope.row.amount
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="!isAnd"
            prop="dateString"
            min-width="200"
            label="时间"
          >
          </el-table-column>
          <el-table-column
            v-if="!isAnd"
            prop="offerMoney"
            min-width="150"
            label="Offer业绩"
          >
            <template #default="scope">
              <span class="tabHref" @click="showDig(scope.row, 'Offer业绩')">{{
                scope.row.offerMoney
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="!isAnd"
            prop="entryMoney"
            min-width="150"
            label="预到岗业绩"
          >
            <template #default="scope">
              <span class="tabHref" @click="showDig(scope.row, '预到岗业绩')">{{
                scope.row.entryMoney
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="!isAnd"
            prop="enteredMoney"
            min-width="150"
            label="实际到岗业绩"
          >
            <template #default="scope">
              <span
                class="tabHref"
                @click="showDig(scope.row, '实际到岗业绩')"
                >{{ scope.row.enteredMoney }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            v-if="!isAnd"
            prop="invoiceMoney"
            min-width="150"
            label="开票业绩"
          >
            <template #default="scope">
              <span class="tabHref" @click="showDig(scope.row, '开票业绩')">{{
                scope.row.invoiceMoney
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="!isAnd"
            prop="accountMoney"
            min-width="150"
            label="回款业绩"
          >
            <template #default="scope">
              <span class="tabHref" @click="showDig(scope.row, '回款业绩')">{{
                scope.row.accountMoney
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="filter.timeType == '按月'"
            prop="target"
            label="目标"
            min-width="150"
          >
          </el-table-column>
          <el-table-column
            v-if="filter.timeType == '按月'"
            prop="difference"
            label="差额"
            min-width="150"
          >
            <template #default="scope">
              <span>{{
                scope.row.difference > 0 ? scope.row.difference : 0
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="filter.timeType == '按月'"
            prop="achieveRate"
            label="完成率（%）"
            min-width="150"
          >
          </el-table-column>
        </el-table>
        <el-pagination
          :hide-on-single-page="filter.page.total > 10 ? false : true"
          v-if="filter.page.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="filter.page.current"
          :page-size="filter.page.size"
          :pager-count="5"
          layout="total,prev, pager, next,sizes"
          :total="filter.page.total"
          :page-sizes="[10, 20, 50, 100]"
          style="padding: 10px 15px 20px"
        ></el-pagination>
      </div>
      <div
       v-show="activeName=='second'"
        class="pd40"
        style="background-color: #fff; padding-top: 10px; padding-bottom: 20px"
      >
      <el-table
          :data="ListData"
          v-loading="listLoading"
          :header-cell-style="{
            'background-color': '#f5f7fa',
            'border-top': '1px #e5e5e5 solid',
          }"
          v-horizontal-scroll
          v-sticky="{ top: 0, parent: '.scollDom' }"
          class="tabBorder custor notableBorder"
          :span-method="objectSpanMethod"
          style="width: 100%; margin: 0px; border-top: 0"
        >
        <el-table-column
            prop="sourceName"
            min-width="150"
            label="对象"
            fixed
            align="left"
          >
            <template #default="scope">
              <span
                v-if="scope.row.sourceType !== '顾问'"
                class="tabHref"
                @click="getDataThrough(scope.row)"
                >{{ scope.row.sourceName }}</span
              >
              <span v-else>{{ scope.row.sourceName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="dateString"
            min-width="200"
            label="时间"
            fixed
            align="left"
          >
          </el-table-column>
          <el-table-column
            prop="companyName"
            min-width="200"
            label="客户名称"
            fixed
            align="left"
          >
          <template #default="scope">
            <span class="tabHref" @click="detailsPop(scope.row.companyId)">{{
                scope.row.companyName?scope.row.companyName:'-'
              }}</span>
          </template>
          </el-table-column>
          <el-table-column
            prop="offerMoney"
            min-width="150"
            label="Offer业绩"
          >
            <template #default="scope">
              <span class="tabHref" @click="showDig(scope.row, 'Offer业绩')">{{
                scope.row.offerMoney
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="lastYearOfferMoney"
            min-width="150"
            label="去年同期Offer业绩"
          >
            <template #default="scope">
              <span class="tabHref" @click="showDig(scope.row, 'Offer业绩','lastYear')">{{
                scope.row.lastYearOfferMoney!==null?scope.row.lastYearOfferMoney:'-'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="offerMoneyComparison"
            min-width="150"
            label="Offer业绩同期比"
          >
            <template #default="scope">
              <span :style="scope.row.offerMoneyComparison>0?{color:'#606266'}:scope.row.offerMoneyComparison?{color:'#e60012'}:{color:'#606266'}">{{
                scope.row.offerMoneyComparison!==null?scope.row.offerMoneyComparison+'%':'/'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="enteredMoney"
            min-width="150"
            label="到岗业绩"
          >
            <template #default="scope">
              <span
                class="tabHref"
                @click="showDig(scope.row, '实际到岗业绩')"
                >{{ scope.row.enteredMoney }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="lastYearEnteredMoney"
            min-width="150"
            label="去年同期到岗业绩"
          >
            <template #default="scope">
              <span
                class="tabHref"
                @click="showDig(scope.row, '实际到岗业绩','lastYear')"
                >{{ scope.row.lastYearEnteredMoney!==null? scope.row.lastYearEnteredMoney:'-' }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="enteredMoneyComparison"
            min-width="150"
            label="到岗业绩同期比"
          >
            <template #default="scope">
              <span :style="scope.row.enteredMoneyComparison>0?{color:'#606266'}:scope.row.enteredMoneyComparison?{color:'#e60012'}:{color:'#606266'}">{{
                scope.row.enteredMoneyComparison!==null?scope.row.enteredMoneyComparison+'%':'/'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="invoiceMoney"
            min-width="150"
            label="开票业绩"
          >
            <template #default="scope">
              <span class="tabHref" @click="showDig(scope.row, '开票业绩')">{{
                scope.row.invoiceMoney
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="lastYearInvoiceMoney"
            min-width="150"
            label="去年同期开票业绩"
          >
            <template #default="scope">
              <span class="tabHref" @click="showDig(scope.row, '开票业绩','lastYear')">{{
                scope.row.lastYearInvoiceMoney!==null?scope.row.lastYearInvoiceMoney:'-'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="invoiceMoneyComparison"
            min-width="150"
            label="开票业绩同期比"
          >
            <template #default="scope">
              <span :style="scope.row.invoiceMoneyComparison>0?{color:'#606266'}:scope.row.invoiceMoneyComparison?{color:'#e60012'}:{color:'#606266'}">{{
                scope.row.invoiceMoneyComparison!==null?scope.row.invoiceMoneyComparison+'%':'/'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="accountMoney"
            min-width="150"
            label="回款业绩"
          >
            <template #default="scope">
              <span class="tabHref" @click="showDig(scope.row, '回款业绩')">{{
                scope.row.accountMoney
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="lastYearAccountMoney"
            min-width="150"
            label="去年同期回款业绩"
          >
            <template #default="scope">
              <span class="tabHref" @click="showDig(scope.row, '回款业绩','lastYear')">{{
                scope.row.lastYearAccountMoney!==null?scope.row.lastYearAccountMoney:'-'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="accountMoneyComparison"
            min-width="150"
            label="回款业绩同期比"
          >
            <template #default="scope">
              <span :style="scope.row.accountMoneyComparison>0?{color:'#606266'}:scope.row.accountMoneyComparison?{color:'#e60012'}:{color:'#606266'}">{{
                scope.row.accountMoneyComparison!==null?scope.row.accountMoneyComparison+'%':'/'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="offerCount"
            min-width="150"
            label="Offer"
          >
            <template #default="scope">
              <span class="tabHref" @click="dialogVisibletrue(scope.row, 'Offer')">{{
                scope.row.offerCount
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="failOfferCount"
            min-width="150"
            label="失败Offer"
          >
            <template #default="scope">
              <span class="tabHref" @click="dialogVisibletrue(scope.row, '失败Offer')">{{
                scope.row.failOfferCount
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="projectBd"
            min-width="150"
            label="项目BD"
          >
            <template #default="scope">
              <span class="tabHref" @click="dialogVisibletrue(scope.row, '项目BD')">{{
                scope.row.projectBd
              }}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :hide-on-single-page="filter.page.total > 10 ? false : true"
          v-if="filter.page.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="filter.page.current"
          :page-size="filter.page.size"
          :pager-count="5"
          layout="total,prev, pager, next,sizes"
          :total="filter.page.total"
          :page-sizes="[10, 20, 50, 100]"
          style="padding: 10px 15px 20px"
        ></el-pagination>
      </div>
      <div
       v-show="activeName=='three'"
        class="pd40"
        style="background-color: #fff; padding-top: 10px; padding-bottom: 20px"
      >
      <el-table
          :data="ListData"
          v-loading="listLoading"
          :header-cell-style="{
            'background-color': '#f5f7fa',
            'border-top': '1px #e5e5e5 solid',
          }"
          v-horizontal-scroll
          v-sticky="{ top: 0, parent: '.scollDom' }"
          class="tabBorder custor notableBorder"
          :span-method="objectSpanMethod"
          style="width: 100%; margin: 0px; border-top: 0"
        >
        <el-table-column
            prop="companyName"
            min-width="200"
            label="客户名称"
            fixed
            align="left"
          >
          <template #default="scope">
            <span class="tabHref" @click="detailsPop(scope.row.companyId)">{{
                scope.row.companyName?scope.row.companyName:'-'
              }}</span>
          </template>
          </el-table-column>
          <el-table-column
            prop="dateString"
            min-width="200"
            label="时间"
            fixed
            align="left"
          >
          </el-table-column>
          <el-table-column
            prop="sourceName"
            min-width="150"
            label="对象"
            fixed
            align="left"
          >
            <template #default="scope">
              <span
                v-if="scope.row.sourceType !== '顾问'"
                class="tabHref"
                @click="getDataThrough(scope.row)"
                >{{ scope.row.sourceName }}</span
              >
              <span v-else>{{ scope.row.sourceName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="offerMoney"
            min-width="150"
            label="Offer业绩"
          >
            <template #default="scope">
              <span class="tabHref" @click="showDig(scope.row, 'Offer业绩')">{{
                scope.row.offerMoney
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="lastYearOfferMoney"
            min-width="150"
            label="去年同期Offer业绩"
          >
            <template #default="scope">
              <span class="tabHref" @click="showDig(scope.row, 'Offer业绩','lastYear')">{{
                scope.row.lastYearOfferMoney!==null?scope.row.lastYearOfferMoney:'-'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="offerMoneyComparison"
            min-width="150"
            label="Offer业绩同期比"
          >
            <template #default="scope">
              <span :style="scope.row.offerMoneyComparison>0?{color:'#606266'}:scope.row.offerMoneyComparison?{color:'#e60012'}:{color:'#606266'}">{{
                scope.row.offerMoneyComparison!==null?scope.row.offerMoneyComparison+'%':'/'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="enteredMoney"
            min-width="150"
            label="到岗业绩"
          >
            <template #default="scope">
              <span
                class="tabHref"
                @click="showDig(scope.row, '实际到岗业绩')"
                >{{ scope.row.enteredMoney }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="lastYearEnteredMoney"
            min-width="150"
            label="去年同期到岗业绩"
          >
            <template #default="scope">
              <span
                class="tabHref"
                @click="showDig(scope.row, '实际到岗业绩','lastYear')"
                >{{ scope.row.lastYearEnteredMoney!==null? scope.row.lastYearEnteredMoney:'-' }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="enteredMoneyComparison"
            min-width="150"
            label="到岗业绩同期比"
          >
            <template #default="scope">
              <span :style="scope.row.enteredMoneyComparison>0?{color:'#606266'}:scope.row.enteredMoneyComparison?{color:'#e60012'}:{color:'#606266'}">{{
                scope.row.enteredMoneyComparison!==null?scope.row.enteredMoneyComparison+'%':'/'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="invoiceMoney"
            min-width="150"
            label="开票业绩"
          >
            <template #default="scope">
              <span class="tabHref" @click="showDig(scope.row, '开票业绩')">{{
                scope.row.invoiceMoney
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="lastYearInvoiceMoney"
            min-width="150"
            label="去年同期开票业绩"
          >
            <template #default="scope">
              <span class="tabHref" @click="showDig(scope.row, '开票业绩','lastYear')">{{
                scope.row.lastYearInvoiceMoney!==null?scope.row.lastYearInvoiceMoney:'-'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="invoiceMoneyComparison"
            min-width="150"
            label="开票业绩同期比"
          >
            <template #default="scope">
              <span :style="scope.row.invoiceMoneyComparison>0?{color:'#606266'}:scope.row.invoiceMoneyComparison?{color:'#e60012'}:{color:'#606266'}">{{
                scope.row.invoiceMoneyComparison!==null?scope.row.invoiceMoneyComparison+'%':'/'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="accountMoney"
            min-width="150"
            label="回款业绩"
          >
            <template #default="scope">
              <span class="tabHref" @click="showDig(scope.row, '回款业绩')">{{
                scope.row.accountMoney
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="lastYearAccountMoney"
            min-width="150"
            label="去年同期回款业绩"
          >
            <template #default="scope">
              <span class="tabHref" @click="showDig(scope.row, '回款业绩','lastYear')">{{
                scope.row.lastYearAccountMoney!==null?scope.row.lastYearAccountMoney:'-'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="accountMoneyComparison"
            min-width="150"
            label="回款业绩同期比"
          >
            <template #default="scope">
              <span :style="scope.row.accountMoneyComparison>0?{color:'#606266'}:scope.row.accountMoneyComparison?{color:'#e60012'}:{color:'#606266'}">{{
                scope.row.accountMoneyComparison!==null?scope.row.accountMoneyComparison+'%':'/'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="offerCount"
            min-width="150"
            label="Offer"
          >
            <template #default="scope">
              <span class="tabHref" @click="dialogVisibletrue(scope.row, 'Offer')">{{
                scope.row.offerCount
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="failOfferCount"
            min-width="150"
            label="失败Offer"
          >
            <template #default="scope">
              <span class="tabHref" @click="dialogVisibletrue(scope.row, '失败Offer')">{{
                scope.row.failOfferCount
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="projectBd"
            min-width="150"
            label="项目BD"
          >
            <template #default="scope">
              <span class="tabHref" @click="dialogVisibletrue(scope.row, '项目BD')">{{
                scope.row.projectBd
              }}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :hide-on-single-page="filter.page.total > 10 ? false : true"
          v-if="filter.page.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="filter.page.current"
          :page-size="filter.page.size"
          :pager-count="5"
          layout="total,prev, pager, next,sizes"
          :total="filter.page.total"
          :page-sizes="[10, 20, 50, 100]"
          style="padding: 10px 15px 20px"
        ></el-pagination>
      </div>
    </div>
    <el-drawer
      :visible.sync="dialogDetailsVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="cancel"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">
        {{ titName }}
      </div>
      <el-row class="drawerCon" style="padding: 10px 10px 5px">
        <el-col :span="24" style="">
          <el-button
            size="mini"
            @click="geDetailExport"
            :loading="exportBtnLoading1"
            >导出</el-button
          >
          <el-pagination
            style="float: right"
            :hide-on-single-page="page.total > 10 ? false : true"
            v-if="page.total > 0"
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="page.current"
            :page-size="page.size"
            :pager-count="5"
            layout="total,prev, pager, next,sizes"
            :total="page.total"
            :page-sizes="[10, 20, 50, 100]"
          ></el-pagination>
        </el-col>
      </el-row>
      <div>
        <el-table
          :data="digData"
          v-loading="listLoading1"
          :header-cell-style="{
            'background-color': '#f5f7fa',
          }"
          class="tabBorder custor"
          style="width: 100%"
        >
          <el-table-column
            prop="lastModifiedTime"
            min-width="100"
            :label="
              titName == '回款业绩' || titName == '业绩额' ? '回款时间' : '时间'
            "
          >
          </el-table-column>
          <el-table-column
            v-if="titName == '回款业绩' || titName == '业绩额'"
            prop="actualMakeInvoiceDate"
            min-width="100"
            label="开票时间"
          >
          </el-table-column>
          <el-table-column prop="amount" label="总业绩"></el-table-column>
          <el-table-column prop="achievement" label="实际业绩" min-width="100">
          </el-table-column>
          <el-table-column
            v-if="
              titName == 'Offer业绩' ||
              titName == '预到岗业绩' ||
              titName == '实际到岗业绩'
            "
            prop="achievementCreator"
            label="业绩创造者"
            min-width="120"
          >
          </el-table-column>
          <el-table-column
            v-if="
              titName == 'Offer业绩' ||
              titName == '预到岗业绩' ||
              titName == '实际到岗业绩'
            "
            prop="type"
            min-width="100"
            label="业绩类型"
          >
          </el-table-column>
          <el-table-column
            v-if="
              titName == 'Offer业绩' ||
              titName == '预到岗业绩' ||
              titName == '实际到岗业绩'
            "
            prop="allocatePercentStr"
            label="分配比例（个人）"
            min-width="140"
          >
          </el-table-column>
          <el-table-column
            v-if="
              titName == '开票业绩' ||
              titName == '回款业绩' ||
              titName == '业绩额'
            "
            prop="nickName"
            min-width="130"
            label="业绩创造者"
          >
          </el-table-column>
          <el-table-column
            v-if="
              titName == '开票业绩' ||
              titName == '回款业绩' ||
              titName == '业绩额'
            "
            min-width="100"
            prop="roleName"
            label="业绩类型"
          >
          </el-table-column>
          <el-table-column
            min-width="140"
            v-if="
              titName == '开票业绩' ||
              titName == '回款业绩' ||
              titName == '业绩额'
            "
            prop="allocatePercentStr"
            label="分配比例（个人）"
          >
          </el-table-column>
          <el-table-column
            prop="companyName"
            label="客户名称"
            min-width="200"
          >
          </el-table-column>
          <el-table-column prop="projectName" label="项目名称" width="270">
            <template #default="scope">
              <router-link
                class="tabHref"
                target="_blank"
                style="text-decoration: none"
                :to="{
                  path: '/project/details',
                  query: { id: scope.row.projectId },
                }"
                >{{ scope.row.projectName }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column prop="resumeName" label="候选人" min-width="100">
            <template #default="scope">
              <span class="tabHref" @click="showDrawer(scope.row.resumeId)">{{
                scope.row.resumeName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="industryName" label="行业" min-width="100">
            <template #default="scope">
              {{ scope.row.industryName ? scope.row.industryName : "-" }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :hide-on-single-page="page.total > 10 ? false : true"
          v-if="page.total > 0"
          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
          :current-page="page.current"
          :page-size="page.size"
          :pager-count="5"
          layout="total,prev, pager, next,sizes"
          :total="page.total"
          :page-sizes="[10, 20, 50, 100]"
        ></el-pagination>
      </div>
    </el-drawer>
    <!-- 抽屉弹窗 -->
    <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">
        <i
          v-if="drawerTitle == '客户'"
          class="drawIcon iconfont icon-kh_xq"
        ></i>
        <i
          v-if="drawerTitle == '人才'"
          class="drawIcon iconfont icon-rc_bt"
        ></i>
        {{ drawerTitle }}
      </div>
      <div class="drawerCon"  :style="drawerTitle == '人才' ? 'padding:0' : ''">
        <addoreEditCus
          v-if="drawerTitle == '编辑客户' && drawerVisible"
          @loadList="loadList"
          @tuComInfo="detailsPop"
          :customId="customId"
        ></addoreEditCus>
        <customerInfo
          v-if="drawerTitle == '客户' && drawerVisible"
          @toEdit="toEditComInfo"
          :customId="customId"
          :activeToInfo="activedToInfo"
          @loadList="loadList"
        ></customerInfo>
        <resumeDetails
        v-if="drawerTitle == '人才' && drawerVisible"
          :resumeDetailId="resumeId"
          :hover="hoverInfo"
          :repeatResume="repeatResume"
        ></resumeDetails>
      </div>
    </el-drawer>
    <el-drawer
        :visible.sync="dialogVisible"
        custom-class="drawerClass"
        direction="ttb"
        :modal="false"
        append-to-body
        :destroy-on-close="true"
      >
        <div slot="title" class="drawerTitle">
          {{ dialogTitle }}
        </div>
        <div class="drawerCon">
       <performanceDig :dialogTitle='dialogTitle' :info="digInfo" v-if="dialogVisible"></performanceDig>
        </div>
      </el-drawer>
  </section>
</template>
<script>
import {
  permissionTree, //小类
  permissionType, // 大类
  achieveChartList, //列表
  achieveChartExport, //列表导出
  achieveChartDetail, //详情Offer业绩预到岗业绩实际到岗业绩
  achieveChartDetailInvoice, //详情开票业绩
  achieveChartDetailAccount, //详情回款业绩
  achieveChartExportAchieve, //Offer业绩预到岗业绩实际到岗业绩导出
  achieveChartExportInvoice, //开票业绩导出
  achieveChartExportAccount, //回款业绩导出
  dataThrough, //业绩报表数透
  exportDataThrough, //导出业绩报表数透
  achieveChartInvoiceAndAccount, //业绩报表根据开票与回款时间进行分页
  achieveChartExportInvoiceAndAccount, //导出业绩报表且查询
  achieveChartTeamCompanyPageList,//团队客户分页列表
achieveChartTeamCompanyDataThrough,//团队的客户业绩数透
achieveChartTeamCompanyExport,//导出团队客户业绩列表
achieveChartTeamCompanyExportDataThrough,//导出业绩报表团队的客户业绩数透
achieveChartCompanyTeamPageList,//客户的团队分页列表
achieveChartCompanyTeamDataThrough,//客户的团队业绩数透
achieveChartCompanyTeamExport,//导出客户的团队业绩列表
achieveChartCompanyTeamExportDataThrough,//导出业绩报表客户的团队业绩数透
getCompanyBaseInfos,
} from "../../api/api";
import specialCom from "../setting/specialCom";
import resumeDetails from "../../components/resumeDetail";
import customerInfo from "./../../components/customer/customerInfo";
import addoreEditCus from "./../../components/customer/addorEditCustomer";
import quarter from "../../components/report/quarter";
import performanceDig from "../../components/report/performanceDig";
import { ApiBaseUrl } from "@/api/http";
export default {
  components: { specialCom, resumeDetails, quarter,customerInfo,addoreEditCus,performanceDig },
  data() {
    return {
      filter: {
        //查询条件
        time: [
          new Date().getFullYear() + "-01-01",
          new Date(Date.now()).Format("yyyy-MM-dd"),
        ],
        makeInvoicetime: [
          new Date().getFullYear() + "-01-01",
          new Date(Date.now()).Format("yyyy-MM-dd"),
        ],
        accounttime: [
          new Date().getFullYear() + "-01-01",
          new Date(Date.now()).Format("yyyy-MM-dd"),
        ],
        timeType: "总计",
        searchType: "顾问",
        sourceIds: [],
        show: false, // 默认不展示停用数据
        page: {
          current: 1,
          size: 50,
          total: 0,
        },
        dateSearchType: "ATTRIBUTION",
        companyIds:[]
      },
      timeTypeList: ["按季", "按月", "按周", "按日", "总计"],
      timeSecTypeList: ["按年","按季", "按月", "总计"],
      optionsList: [],
      exportBtnLoading: false,
      exportBtnLoading1: false,
      ListData: [],
      sourceIds: [],
      searchIdList: [],
      options2: [],
      firstVal: "",
      kpiPlanInput: {},
      props: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
      },
      listLoading: false,
      listLoading1: false,
      dialogVisible: false,
      dialogDetailsVisible:false,
      titName: "",
      digData: [],
      drawerVisible: false,
      repeatResume: { type: "列表", valId: "" },
      resumeId: 254,
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
      page: {
        total: 0,
        current: 1,
        size: 50,
      },
      baseUrl: ApiBaseUrl,
      userId: parseInt(this.getStore("uid")),
      sourceId: "",
      startDate: "",
      endDate: "",
      chartType: "",
      companyId:'',
      diawData: {
        item: null,
        val: null,
      },
      dataThrough: {},
      isAnd: false,
      activeName:'first',
      activedToInfo: "1",
      drawerTitle: "",
      customId: null,
      spanArr: [],
      dialogTitle:'',
      digInfo:{},
      ComBaseList:[],
      filterComBaseList:[],
      searchValue:'',
      yearVal:null
    };
  },
  watch: {
    drawerVisible(newValue) {
      if (newValue) {
        this.noScroll(); //禁止滚动
      } else {
        this.canScroll();
      }
    },
    dialogVisible(newValue) {
      if (newValue) {
        this.noScroll(); //禁止滚动
      } else {
        this.canScroll();
      }
    },
    dialogDetailsVisible(newValue) {
      if (newValue) {
        this.noScroll(); //禁止滚动
      } else {
        this.canScroll();
      }
    },
  },
  created() {
    this.permissionType();
    this.permissionTree("顾问", true);
    this.sourceIds.push(this.userId);
    this.loadComBaseList()
    this.loadInfo();
  },
  mounted() {
    this.noScroll(); //禁止滚动
  },
  destroyed() {
    this.canScroll();
  },
  methods: {
     //tab切换
     handleClick(tab) {
      this.activeName = tab.name;
      this.filter.time=[
        new Date().getFullYear() + "-01-01",
        new Date(Date.now()).Format("yyyy-MM-dd"),
      ]
      this.sourceIds=[]
      this.filter.sourceIds=[]
      this.sourceIds.push(this.userId);
      this.filter.timeType="总计";
      this.filter.searchType= "顾问";
      this.filter.dateSearchType="ATTRIBUTION";
      this.filter.companyIds=[];
      this.filter.show=false;
      this.yearVal=null
      this.permissionTree("顾问", true);
      this.loadInfo();
    },
    filterFun(data, value) {
      // 弹窗 - 拼音过滤
      return data.data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    // 查询
    loadInfo() {
      this.filter.page.total = 0;
      this.filter.page.current = 1;
      this.filter.page.size = 50;
      if (!this.filter.searchType || this.sourceIds.length == 0) {
        this.$message.error("对象必填，请选择对象！");
        this.ListData = [];
        return false;
      }
      this.loadInfolist();
    },
    //选择大类
    changeOption(val) {
      this.searchIdList = "";
      this.sourceIds = [];
      this.filter.page.current = 1;
      if (val) {
        this.permissionTree(val);
      }
    },
    // 查询维度
    // 大类
    permissionType() {
      permissionType().then((res) => {
        if (res.success) {
          this.optionsList = res.result;
        }
      });
    },
    // 小类
    permissionTree(val, isfirst) {
      permissionTree({ type: val, showDisableUser: this.filter.show }).then(
        (res) => {
          if (res.success) {
            this.options2 = res.result;
            // 如果是初次加载页面数据，在options2中查找userId对应的searchIdList
            if (isfirst) {
              this.searchIdList = [];
              let list = this.changeDetSelect(this.userId, this.options2);
              this.searchIdList.push(list);
            }
          }
        }
      );
    },
    //停用账号
    isStop(val) {
      this.filter.show = val;
      this.filter.searchType = "顾问";
      this.searchIdList = "";
      this.sourceIds = [];
      this.filter.page.current = 1;
      this.permissionType();
      this.permissionTree("顾问", true);
      this.sourceIds.push(this.userId);
      this.loadInfolist();
    },
    // //回显(多级)
    changeDetSelect(key, treeData) {
      let arr = []; // 在递归时操作的数组
      let returnArr = []; // 存放结果的数组
      let depth = 0; // 定义全局层级
      // 定义递归函数
      function childrenEach(childrenData, depthN) {
        for (var j = 0; j < childrenData.length; j++) {
          depth = depthN; // 将执行的层级赋值 到 全局层级
          arr[depthN] = childrenData[j].id;
          if (childrenData[j].id == key) {
            returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
            break;
          } else {
            if (
              childrenData[j].children &&
              childrenData[j].children.length > 0
            ) {
              depth++;
              childrenEach(childrenData[j].children, depth);
            }
          }
        }
        return returnArr;
      }

      return childrenEach(treeData, depth);
    },
    //业绩导出
    exportList() {
      this.exportBtnLoading = true;
      let setData = false;
      let isconfirm = true;
      var int = setTimeout(function () {
        let that = this;
        setData = true;
        clearInterval(int);
        let div =
          "<p style='font-weight:bold'>后台处理中，稍后请在 " +
          "<a href='/tools/exportingRecords' style='color:#526ecc'>导出记录</a>" +
          " 获取文件</p>";
        if (isconfirm) {
          that.$globalHub
            .$confirm(div, "提示", {
              showCancelButton: false,
              showConfirmButton: false,
              customClass: "altCls",
              dangerouslyUseHTMLString: true,
            })
            .then(() => {})
            .catch(() => {});
        }
      }, 5000);
      if(this.activeName=='first'){
        let data = {
        sourceIds: this.sourceIds,
        startDate: this.filter.time ? this.filter.time[0] : "",
        endDate: this.filter.time ? this.filter.time[1] : "",
        page: this.filter.page,
        show: this.filter.show,
        sourceType: this.filter.searchType,
        timeType: this.filter.timeType,
        dateSearchType: this.filter.dateSearchType,
      };
      let data1 = {
        sourceIds: this.sourceIds,
        accountStartDate: this.filter.accounttime
          ? this.filter.accounttime[0]
          : "",
        accountEndDate: this.filter.accounttime
          ? this.filter.accounttime[1]
          : "",
        makeInvoiceStartDate: this.filter.makeInvoicetime
          ? this.filter.makeInvoicetime[0]
          : "",
        makeInvoiceEndDate: this.filter.makeInvoicetime
          ? this.filter.makeInvoicetime[1]
          : "",
        page: this.filter.page,
        sourceType: this.filter.searchType,
        dateSearchType: this.filter.dateSearchType,
      };
      if (this.dataThrough.sourceId) {
        exportDataThrough(this.dataThrough).then((res) => {
          if (res.success) {
            if (!setData) {
              isconfirm = false;
              location.href = this.baseUrl + res.result;
            }
          }
          this.exportBtnLoading = false;
        });
      } else if (this.isAnd) {
        achieveChartExportInvoiceAndAccount(data1).then((res) => {
          if (res.success) {
            if (!setData) {
              isconfirm = false;
              location.href = this.baseUrl + res.result;
            }
          }
          this.exportBtnLoading = false;
        });
      } else {
        achieveChartExport(data).then((res) => {
          if (res.success) {
            if (!setData) {
              isconfirm = false;
              location.href = this.baseUrl + res.result;
            }
          }
          this.exportBtnLoading = false;
        });
      }
      }else if(this.activeName=='second'){
        let data = {
          sourceIds: this.sourceIds,
          startDate: this.filter.time ? this.filter.time[0] : "",
          endDate: this.filter.time ? this.filter.time[1] : "",
          page: this.filter.page,
          show: this.filter.show,
          sourceType: this.filter.searchType,
          companyIds:this.filter.companyIds,
          dateSearchType: this.filter.dateSearchType,
          timeType: this.filter.timeType,
      };
        if (this.dataThrough.sourceId) {
          achieveChartTeamCompanyExportDataThrough(this.dataThrough).then((res) => {
          if (res.success) {
            if (!setData) {
              isconfirm = false;
              location.href = this.baseUrl + res.result;
            }
          }
          this.exportBtnLoading = false;
        });
      } else {
        achieveChartTeamCompanyExport(data).then((res) => {
          if (res.success) {
            if (!setData) {
              isconfirm = false;
              location.href = this.baseUrl + res.result;
            }
          }
          this.exportBtnLoading = false;
        });
      } 
      }else if(this.activeName=='three'){
        let data = {
          sourceIds: this.sourceIds,
          startDate: this.filter.time ? this.filter.time[0] : "",
          endDate: this.filter.time ? this.filter.time[1] : "",
          page: this.filter.page,
          show: this.filter.show,
          sourceType: this.filter.searchType,
          companyIds:this.filter.companyIds,
          dateSearchType: this.filter.dateSearchType,
          timeType: this.filter.timeType,
      };
        if (this.dataThrough.sourceId) {
          achieveChartCompanyTeamExportDataThrough(this.dataThrough).then((res) => {
          if (res.success) {
            if (!setData) {
              isconfirm = false;
              location.href = this.baseUrl + res.result;
            }
          }
          this.exportBtnLoading = false;
        });
      } else {
        achieveChartCompanyTeamExport(data).then((res) => {
          if (res.success) {
            if (!setData) {
              isconfirm = false;
              location.href = this.baseUrl + res.result;
            }
          }
          this.exportBtnLoading = false;
        });
      } 
      }
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.filter.page.size = val;
      this.filter.page.current = 1;
      this.loadInfolist();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.filter.page.current = val;
      this.loadInfolist();
    },
    handleSizeChange1(val) {
      //pageSize 改变时会触发
      this.page.size = val;
      this.page.current = 1;
      this.geDetailList(this.diawData.item, this.diawData.val);
    },
    handleCurrentChange1(val) {
      //currentPage 改变时会触发
      this.page.current = val;
      this.geDetailList(this.diawData.item, this.diawData.val);
    },
    getSearch(val) {
      const _nodeId = this.$refs.dataRef.getCheckedNodes(true);
      this.sourceIds = this.searchIdList.map((i) => {
        return i[i.length - 1];
      });
      this.loadInfo();
    },
    //获取表格数据
    loadInfolist() {
      $(".scollDom").scrollTop(0);
      this.listLoading = true;
      let req = null;
      if(this.activeName=='first'){
        if (this.isAnd) {
        let data = {
          sourceIds: this.sourceIds,
          accountStartDate: this.filter.accounttime
            ? this.filter.accounttime[0]
            : "",
          accountEndDate: this.filter.accounttime
            ? this.filter.accounttime[1]
            : "",
          makeInvoiceStartDate: this.filter.makeInvoicetime
            ? this.filter.makeInvoicetime[0]
            : "",
          makeInvoiceEndDate: this.filter.makeInvoicetime
            ? this.filter.makeInvoicetime[1]
            : "",
          page: this.filter.page,
          sourceType: this.filter.searchType,
          dateSearchType: this.filter.dateSearchType,
        };
        req = achieveChartInvoiceAndAccount(data);
      } else {
        let data = {
          sourceIds: this.sourceIds,
          startDate: this.filter.time ? this.filter.time[0] : "",
          endDate: this.filter.time ? this.filter.time[1] : "",
          page: this.filter.page,
          show: this.filter.show,
          sourceType: this.filter.searchType,
          timeType: this.filter.timeType,
          dateSearchType: this.filter.dateSearchType,
        };
        req = achieveChartList(data);
      }
      }else if(this.activeName=='second'){
        let data = {
          sourceIds: this.sourceIds,
          startDate: this.filter.time ? this.filter.time[0] : "",
          endDate: this.filter.time ? this.filter.time[1] : "",
          page: this.filter.page,
          show: this.filter.show,
          sourceType: this.filter.searchType,
          companyIds:this.filter.companyIds,
          dateSearchType: this.filter.dateSearchType,
          timeType: this.filter.timeType,
        }
          req = achieveChartTeamCompanyPageList(data);
        }else if(this.activeName=='three'){
        let data = {
          sourceIds: this.sourceIds,
          startDate: this.filter.time ? this.filter.time[0] : "",
          endDate: this.filter.time ? this.filter.time[1] : "",
          page: this.filter.page,
          show: this.filter.show,
          sourceType: this.filter.searchType,
          companyIds:this.filter.companyIds,
          dateSearchType: this.filter.dateSearchType,
          timeType: this.filter.timeType,
        }
          req = achieveChartCompanyTeamPageList(data);
        }
      req.then((res) => {
        if (res.success) {
          this.ListData = res.result.records;
          this.filter.page.total = res.result.total;
          this.dataThrough = {};
          if(this.activeName=='second'||this.activeName=='three'){
            let pos = 0;
          this.spanArr = [];
          for (var i = 0; i < this.ListData.length; i++) {
            if (i === 0) {
              this.spanArr.push(1);
              pos = 0;
            } else {
              // 判断当前元素与上一个元素是否相同,因合并第一个所以[0]
              if(this.activeName=='second'){
                if (
                this.ListData[i].sourceId ===
                this.ListData[i - 1].sourceId&&this.ListData[i].startDate ===
                this.ListData[i - 1].startDate
              ) {
                this.spanArr[pos] += 1;
                this.spanArr.push(0);
              } else {
                this.spanArr.push(1);
                pos = i;
              }
              }else{
                if (
                this.ListData[i].companyId ===
                this.ListData[i - 1].companyId&&this.ListData[i].startDate ===
                this.ListData[i - 1].startDate
              ) {
                this.spanArr[pos] += 1;
                this.spanArr.push(0);
              } else {
                this.spanArr.push(1);
                pos = i;
              }
              }
              
            }
          }
          }
        }
        this.listLoading = false;
      });
    },
    // 表格合并单元格
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex <= 1) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    //取消弹框
    cancel() {
      this.dialogDetailsVisible = false;
      this.page = {
        total: 0,
        current: 1,
        size: 50,
      };
    },
    //显示弹框
    showDig(data, name,type) {
      this.titName = name;
      this.dialogDetailsVisible = true;
      this.geDetailList(data, name,type);
    },
    //关闭抽屉
    handleClose() {
      this.sourceId = "";
      this.startDate = "";
      this.endDate = "";
      this.chartType = "";
      this.companyId=''
      this.drawerVisible = false;
    },
    //弹出抽屉
    showDrawer(id) {
      this.drawerVisible = true;
      this.drawerTitle='人才'
      this.resumeId = id;
    },
    //详情列表数据
    geDetailList(item, val,type) {
      this.listLoading1 = true;
      this.digData = [];
      this.page.total = 0;
      this.diawData.item = item;
      this.diawData.val = val;
      this.sourceId = item.sourceId;
      this.startDate =  type=='lastYear'?item.lastYearStartDate:item.startDate;
      this.endDate = type=='lastYear'?item.lastYearEndDate:item.endDate;
      this.chartType = val;
      this.companyId = item.companyId;
      let data = {
        sourceId: item.sourceId,
        startDate: type=='lastYear'?item.lastYearStartDate:item.startDate,
        endDate: type=='lastYear'?item.lastYearEndDate:item.endDate,
        page: this.page,
        sourceType: item.sourceType,
        timeType: this.filter.timeType,
        chartType: val,
        dateType: this.filter.dateSearchType,
        companyId: item.companyId,
      };
      let data1 = {
        searchId: item.sourceId,
        start:  type=='lastYear'?item.lastYearStartDate:item.startDate,
        end:type=='lastYear'?item.lastYearEndDate:item.endDate,
        page: this.page,
        searchType: item.sourceType,
        timeType: this.filter.timeType,
        chartType: val,
        dateType: this.filter.dateSearchType,
        companyId: item.companyId,
      };
      let data2 = {
        searchId: item.sourceId,
        start: type=='lastYear'?item.lastYearStartDate:this.filter.accounttime ? this.filter.accounttime[0] : "",
        end: type=='lastYear'?item.lastYearEndDate:this.filter.accounttime ? this.filter.accounttime[1] : "",
        makeInvoiceStartDate: this.filter.makeInvoicetime
          ? this.filter.makeInvoicetime[0]
          : "",
        makeInvoiceEndDate: this.filter.makeInvoicetime
          ? this.filter.makeInvoicetime[1]
          : "",
        page: this.page,
        searchType: item.sourceType,
        dateType: this.isAnd?'ACTUAL':this.filter.dateSearchType,
        companyId: item.companyId,
      };
      if (val == "Offer业绩" || val == "预到岗业绩" || val == "实际到岗业绩") {
        achieveChartDetail(data).then((res) => {
          if (res.success) {
            this.digData = res.result.records;
            this.page.total = res.result.total;
          }
          this.listLoading1 = false;
        });
      } else if (val == "开票业绩") {
        achieveChartDetailInvoice(data1).then((res) => {
          if (res.success) {
            this.digData = res.result.records;
            this.page.total = res.result.total;
          }
          this.listLoading1 = false;
        });
      } else if (val == "回款业绩" || val == "业绩额") {
        achieveChartDetailAccount(val == "业绩额" ? data2 : data1).then(
          (res) => {
            if (res.success) {
              this.digData = res.result.records;
              this.page.total = res.result.total;
            }
            this.listLoading1 = false;
          }
        );
      }
    },
    //详情列表数据
    geDetailExport() {
      this.exportBtnLoading1 = true;
      let setData = false;
      let isconfirm = true;
      var int = setTimeout(function () {
        let that = this;
        setData = true;
        clearInterval(int);
        let div =
          "<p style='font-weight:bold'>后台处理中，稍后请在 " +
          "<a href='/tools/exportingRecords' style='color:#526ecc'>导出记录</a>" +
          " 获取文件</p>";
        if (isconfirm) {
          that.$globalHub
            .$confirm(div, "提示", {
              showCancelButton: false,
              showConfirmButton: false,
              customClass: "altCls",
              dangerouslyUseHTMLString: true,
            })
            .then(() => {})
            .catch(() => {});
        }
      }, 5000);
      let data = {
        sourceId: this.sourceId,
        startDate: this.startDate,
        endDate: this.endDate,
        page: this.page,
        sourceType: this.diawData.item.sourceType,
        timeType: this.filter.timeType,
        chartType: this.chartType,
        dateType: this.filter.dateSearchType,
        companyId: this.companyId
      };
      let data1 = {
        searchId: this.sourceId,
        start: this.startDate,
        end: this.endDate,
        page: this.page,
        searchType: this.diawData.item.sourceType,
        timeType: this.filter.timeType,
        chartType: this.chartType,
        dateType: this.filter.dateSearchType,
        companyId: this.companyId
      };
      let data2 = {
        searchId: this.sourceId,
        start: this.filter.accounttime ? this.filter.accounttime[0] : "",
        end: this.filter.accounttime ? this.filter.accounttime[1] : "",
        makeInvoiceStartDate: this.filter.makeInvoicetime
          ? this.filter.makeInvoicetime[0]
          : "",
        makeInvoiceEndDate: this.filter.makeInvoicetime
          ? this.filter.makeInvoicetime[1]
          : "",
        page: this.page,
        searchType: this.diawData.item.sourceType,
        dateType: this.isAnd?'ACTUAL':this.filter.dateSearchType,
        companyId: this.companyId
      };
      if (
        this.chartType == "Offer业绩" ||
        this.chartType == "预到岗业绩" ||
        this.chartType == "实际到岗业绩"
      ) {
        achieveChartExportAchieve(data).then((res) => {
          if (res.success) {
            if (!setData) {
              isconfirm = false;
              location.href = this.baseUrl + res.result;
            }
          }
          this.exportBtnLoading1 = false;
        });
      } else if (this.chartType == "开票业绩") {
        achieveChartExportInvoice(data1).then((res) => {
          if (res.success) {
            if (!setData) {
              isconfirm = false;
              location.href = this.baseUrl + res.result;
            }
          }
          this.exportBtnLoading1 = false;
        });
      } else if (this.chartType == "回款业绩" || this.chartType == "业绩额") {
        achieveChartExportAccount(
          this.chartType == "业绩额" ? data2 : data1
        ).then((res) => {
          if (res.success) {
            location.href = this.baseUrl + res.result;
          }
          this.exportBtnLoading1 = false;
        });
      }
    },
    //数透
    getDataThrough(row) {
      this.listLoading = true;
      let req=null
      if(this.activeName=='first'){
        let data = {
        startDate: row.startDate,
        endDate: row.endDate,
        sourceId: row.sourceId,
        sourceType: row.sourceType,
        timeType: this.filter.timeType,
        show: this.filter.show,
      };
      this.dataThrough = data;
      req=dataThrough(data)
      }else if(this.activeName=='second'){
        let data = {
        startDate: row.startDate,
        endDate: row.endDate,
        sourceId: row.sourceId,
        sourceType: row.sourceType,
        timeType: this.filter.timeType,
        show: this.filter.show,
        companyIds: this.filter.companyIds,
        dateSearchType: this.filter.dateSearchType,
      };
      this.dataThrough = data;
      req=achieveChartTeamCompanyDataThrough(data)
      }else if(this.activeName=='three'){
        let data = {
          startDate: row.startDate,
          endDate: row.endDate,
          sourceId: row.sourceId,
          sourceType: row.sourceType,
          timeType: this.filter.timeType,
          show: this.filter.show,
          companyId: row.companyId,
          dateSearchType: this.filter.dateSearchType,
        };
        this.dataThrough = data;
        req=achieveChartCompanyTeamDataThrough(data)
      }
     req.then((res) => {
        if (res.success) {
          this.ListData = res.result;
          if(this.activeName=='second'||this.activeName=='three'){
            let pos = 0;
          this.spanArr = [];
          for (var i = 0; i < this.ListData.length; i++) {
            if (i === 0) {
              this.spanArr.push(1);
              pos = 0;
            } else {
              // 判断当前元素与上一个元素是否相同,因合并第一个所以[0]
              if(this.activeName=='second'){
                if (
                this.ListData[i].sourceId ===
                this.ListData[i - 1].sourceId&&this.ListData[i].startDate ===
                this.ListData[i - 1].startDate
              ) {
                this.spanArr[pos] += 1;
                this.spanArr.push(0);
              } else {
                this.spanArr.push(1);
                pos = i;
              }
              }else{
                if (
                this.ListData[i].companyId ===
                this.ListData[i - 1].companyId&&this.ListData[i].startDate ===
                this.ListData[i - 1].startDate
              ) {
                this.spanArr[pos] += 1;
                this.spanArr.push(0);
              } else {
                this.spanArr.push(1);
                pos = i;
              }
              }
            }
            }
          }
        }
        this.listLoading = false;
      });
    },
    //获取按季事件
    getDate(val) {
      console.log(val)
      this.filter.time = val;
      this.loadInfo();
    },
    loadList() {
      if (this.drawerTitle == "编辑客户") {
        this.drawerTitle = "客户";
      }
    },
    detailsPop(id) {
      this.drawerVisible = true;
      this.drawerTitle = "客户";
      this.customId = id; //客户id
      this.activedToInfo = "1";
    },
    toEditComInfo(id) {
      this.drawerTitle = "编辑客户";
      this.customId = id;
    },
     // 弹框
     dialogVisibletrue(row,name) {
      this.digInfo={
        endDate:row.endDate,
        item: name,
        label: name,
        sourceId:row.sourceId,
        sourceType: row.sourceType,
        startDate: row.startDate,
        companyId: row.companyId,
        value: "0",
      }
      this.dialogTitle = name;
      this.dialogVisible = true;
    },
    loadComBaseList() {
      //获取客户列表
      getCompanyBaseInfos().then((res) => {
        if (res.success) {
          this.ComBaseList = res.result;
          this.filterComBaseList=this.ComBaseList
        }
      });
    },
    onDropdownInputFocus(){
      this.$refs.selectDropdown.$refs.input.blur=() => {
        this.filterComBaseList=this.ComBaseList
        this.searchValue=''
      }
      this.$refs.selectDropdown.$refs.input.onkeyup=() => {
        this.searchValue= this.$refs.selectDropdown.$refs.input.value
        this.filterComBaseList=this.ComBaseList.filter(item => {
          return !this.searchValue.length||item.name.toLowerCase().includes(this.searchValue.toLowerCase())
        })
      }
    },
    getYear(val){
      console.log(val)
      this.filter.time=[val+'-01-01',val+'-12-31']
      this.loadInfo();
    },
    timeTypeChage(val){
      console.log(val,new Date().getFullYear())
      if(val=='按年'){
        this.yearVal=new Date().getFullYear().toString()
        this.filter.time=[this.yearVal+'-01-01',this.yearVal+'-12-31']
      }else{
        this.yearVal=''
        this.filter.time=[ new Date().getFullYear() + "-01-01",
          new Date(Date.now()).Format("yyyy-MM-dd")]
      }
      this.filter.timeType=val
      if(val!=='按季'){
        this.loadInfo();
      }
    }
  },
};
</script>
<style lang='scss' scoped>
.fl {
  span {
    background: #f1f1f1;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 4px;
    margin: 0 10px;
    color: #333;
  }
  p {
    width: 60px;
    height: 30px !important;
    line-height: 30px !important;
    padding: 0 10px;
    font-size: 13px;
    border: 1px solid #dcdfe6;
    border-radius: 5px 0px 0px 5px;
  }
}
</style>
<style lang='scss'>
.dinput {
  .el-input--suffix .el-input__inner {
    border-radius: 5px 0px 0px 5px;
  }
}
.dinputr {
  border-radius: 0 5px 5px 0;
  border-left: 0;
  .el-input--suffix .el-input__inner {
    border-radius: 0 5px 5px 0;
    border-left: 0;
  }
}
.sechContent {
  .el-date-editor .el-range-separator {
    font-size: 13px;
    color: #606266;
    line-height: 26px;
  }
}
.perfromanceTable,
.drawerClass {
  .el-table .cell {
    padding-left: 20px;
  }
}
</style>