<template>
  <div class="quarter">
    <mark
      style="
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0);
        z-index: 999;
      "
      v-show="showSeason"
      @click.stop="showSeason = false"
    ></mark>
    <el-input
      placeholder="请选择季度"
      v-model="showSeasontxt"
      style="width: 209px"
      @focus="showSeason = true"
      class="dinputr"
    >
      <i slot="prefix" class="el-input__icon el-icon-date"></i>
    </el-input>
    <el-card
      class="box-card"
      style="
        width: 322px;
        padding: 0 3px 20px;
        margin-top: 10px;
        position: fixed;
        z-index: 9999;
        background-color: white !important;
      "
      v-show="showSeason"
    >
      <div
        slot="header"
        class="clearfix"
        style="text-align: center; padding: 0"
      >
        <button
          type="button"
          aria-label="前一年"
          class="
            el-picker-panel__icon-btn
            el-date-picker__prev-btn
            el-icon-d-arrow-left
          "
          @click="prev"
        ></button>
        <span role="button" class="el-date-picker__header-label"
          >{{ year }}年</span
        >
        <button
          type="button"
          aria-label="后一年"
          @click="next"
          class="
            el-picker-panel__icon-btn
            el-date-picker__next-btn
            el-icon-d-arrow-right
          "
        ></button>
      </div>
      <div class="text item" style="text-align: center">
        <el-button
          type="text"
          size="medium"
          style="
            width: 40%;
            color: #606266;
            float: left;
            border: 0;
            margin: 10px 0;
          "
          @click="selectSeason(0)"
          :class="[season == '1' ? 'is-active' : '']"
          >第一季度（1-3月）
        </el-button>
        <el-button
          type="text"
          size="medium"
          style="
            float: right;
            width: 40%;
            color: #606266;
            border: 0;
            margin: 10px 0;
          "
          @click="selectSeason(1)"
          :class="[season == '2' ? 'is-active' : '']"
          >第二季度（4-6月）
        </el-button>
      </div>
      <div class="text item" style="text-align: center">
        <el-button
          type="text"
          size="medium"
          style="
            width: 40%;
            color: #606266;
            float: left;
            border: 0;
            margin: 10px 0;
          "
          @click="selectSeason(2)"
          :class="[season == '3' ? 'is-active' : '']"
          >第三季度（7-9月）
        </el-button>
        <el-button
          type="text"
          size="medium"
          style="
            float: right;
            width: 40%;
            color: #606266;
            border: 0;
            margin: 10px 0;
          "
          @click="selectSeason(3)"
          :class="[season == '4' ? 'is-active' : '']"
          >第四季度（10-12月）
        </el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valueArr: ["-01", "-02", "-03", "-04"], //接口接受的格式（一个季度传一个整体时间）
      //valueArr: ['01-02-03', '04-05-06', '07-08-09', '10-11-12'],//接口接受的格式（一个季度传一个每个的时间，一个季度三个月）
      showSeason: false,
      season: 0,
      year: new Date().getFullYear(),
      showSeasontxt: "",
      showSeasonVal: [],
    };
  },
  created() {
    let month = new Date().getMonth() + 1;
    this.setDateData(month);
  },
  methods: {
    prev() {
      this.season = 0;
      this.year = this.year - 1;
    },
    next() {
      this.season = 0;
      this.year = this.year + 1;
    },
    selectSeason(i) {
      //   this.season = i + 1;
      let month1 = i + 1;
      this.showSeasonVal = `${this.year}` + this.valueArr[i];
      // let arr = this.valueArr[i].split('-')
      // arr.forEach(item => {
      //   this.showSeason += `${this.year}` + "-" + item + ","
      // })
      // // 截取最后一个字符之前的字符
      // this.showSeason = this.showSeason.slice(0, this.showSeason.length - 1)
      this.showSeason = false;
      this.showSeasontxt = `${this.year}年第${this.season}季度`;
      this.setDateData(month1 * 3);
    },
    setDateData(month) {
      let txt = "";
      let val = [];
      if (month == 1 || month == 2 || month == 3) {
        txt = `${this.year}年第1季度`;
        val[0] = `${this.year}-01-01`;
        val[1] = `${this.year}-03-01`;
        this.season = 1;
      } else if (month == 4 || month == 5 || month == 6) {
        txt = `${this.year}年第2季度`;
        val[0] = `${this.year}-04-01`;
        val[1] = `${this.year}-06-01`;
        this.season = 2;
      } else if (month == 7 || (month == 8) || (month == 9)) {
        txt = `${this.year}年第3季度`;
        val[0] = `${this.year}-07-01`;
        val[1] = `${this.year}-09-01`;
        this.season = 3;
      } else if (month == 10 || month == 11 || month == 12) {
        txt = `${this.year}年第4季度`;
        val[0] = `${this.year}-10-01`;
        val[1] = `${this.year}-12-01`;
        this.season = 4;
      }
      this.showSeasontxt = txt;
      this.showSeasonVal = val;
      this.$emit("getDate", this.showSeasonVal);
    },
  },
};
</script>

<style lang='scss' scope>
.dinputr {
  border-radius: 0 5px 5px 0;
  border-left: 0;
  .el-input__inner {
    border-radius: 0 5px 5px 0;
    border-left: 0;
  }
}
.is-active {
  color: #e60012 !important;
  //   border: 0;
}
</style>

<style lang='scss'>
.quarter {
  .el-card__header {
    padding: 7px 15px;
  }
  .el-card__body {
    padding: 15px 20px;
  }
}
</style>
