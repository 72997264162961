<template>
  <div>
    <el-tabs
            v-if="
              dialogTitle == '候选人备注' ||
              dialogTitle == '内部面试' ||
              dialogTitle == '简历推荐' ||
              dialogTitle == '客户面试_初' ||
              dialogTitle == '项目BD' ||
              dialogTitle == 'F2F面试'
            "
            v-model="activeName"
            @tab-click="handleClick"
          >
            <el-tab-pane
              :label="tabData[0].label + '   ' + tabData[0].count"
              name="first"
            ></el-tab-pane>
            <el-tab-pane
              :label="tabData[1].label + '   ' + tabData[1].count"
              name="second"
            ></el-tab-pane>
          </el-tabs>
          <el-button size="mini" @click="exportExl">导出</el-button>
          <el-pagination
            :hide-on-single-page="pagination1.total > 10 ? false : true"
            v-if="pagination1.total > 0"
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="pagination1.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :page-size="pagination1.pageSize"
            layout="total,prev, pager, next,sizes"
            :total="pagination1.total"
          ></el-pagination>
          <table
            class="
              el-table
              tabBorder
              el-table--fit el-table--enable-row-hover
              digTable
              specialS
            "
            cellpadding="0"
            cellspacing="0"
            v-if="isRemark"
          >
            <thead class="has-gutter">
              <tr class="tableTdTrH">
                <th
                  v-for="(i, index) in headerOption"
                  :key="index"
                  :style="
                    i == '详情'
                      ? 'max-width: 20%'
                      : `max-width: ${80 / (headerOption.length - 1)}%`
                  "
                  scope="col"
                >
                  {{ i }}
                </th>
              </tr>
            </thead>
            <tbody>
              <p v-show="isData" style="text-align: center; color: #909399">
                暂无数据
              </p>
              <tr
                v-for="(i, index) in iscusoption1"
                :key="index"
                class="tableTyTrH"
              >
                <td
                  v-for="(ie, ins) in iscusoption1[index].rowData"
                  :key="ins"
                  :style="
                    ie.indexOf('commentColumnId') > 0
                      ? 'max-width: 20%'
                      : `max-width: ${80 / (headerOption.length - 1)}%`
                  "
                  valign="middle"
                >
                  <template v-if="ie.startsWith('[')">
                    <pre
                      style="
                        line-height: 20px;
                        height: calc(100% - 24px);
                        display: flex;
                        align-items: center;
                      "
                      :style="
                        ie.indexOf('commentColumnId') > 0
                          ? ''
                          : 'cursor: pointer;color: #2b80ff;'
                      "
                      v-html="serializeIE(ie)"
                      @click="goNav(ie)"
                    ></pre>
                  </template>
                  <template v-else>
                    <span
                      v-html="internalM(ie, ins)"
                      @click="nativalNav(ie, ins)"
                      style="
                        display: block;
                        height: 100%;
                        align-items: center;
                        overflow: hidden;
                        width: 100%;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      "
                    ></span>
                  </template>
                </td>
                <td
                  v-if="detailData.qualityCheck"
                  :style="`max-width: ${80 / (headerOption.length - 1)}%`"
                >
                  <span
                    v-if="havePermissions"
                    class="tabHref"
                    @click="changeStatus(i)"
                    >{{
                      activeName == "first" ? "标记无效" : "恢复为有效"
                    }}</span
                  >
                  <span v-else>-</span>
                </td>
              </tr>
            </tbody>
          </table>

          <table
            class="
              el-table
              tabBorder
              el-table--fit el-table--enable-row-hover
              digTable
            "
            cellpadding="0"
            cellspacing="0"
            v-else
          >
            <thead class="has-gutter">
              <tr class="tableTdTrH">
                <th
                  v-for="(i, index) in headerOption"
                  :key="index"
                  style="width: 120px"
                  scope="col"
                >
                  {{ i }}
                </th>
              </tr>
            </thead>
            <tbody>
              <p v-show="isData" style="text-align: center; color: #909399">
                暂无数据
              </p>
              <tr
                v-for="(i, index) in iscusoption1"
                :key="index"
                class="tableTyTrH"
              >
                <td
                  v-for="(ie, ins) in iscusoption1[index].rowData"
                  :key="ins"
                  style="width: 120px"
                  @click="goUrl(ie, ins)"
                >
                  <span v-html="renderIE(ie)"></span>
                </td>
                <td
                  v-if="detailData.qualityCheck"
                  style="max-width: ${80 / (headerOption.length - 1)}%"
                >
                  <span
                    v-if="havePermissions"
                    class="tabHref"
                    @click="changeStatus(i)"
                    >{{
                      activeName == "first" ? "标记无效" : "恢复为有效"
                    }}</span
                  >
                  <span v-else>-</span>
                </td>
              </tr>
            </tbody>
          </table>
          <el-pagination
            :hide-on-single-page="pagination1.total > 10 ? false : true"
            v-if="pagination1.total > 0"
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="pagination1.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :page-size="pagination1.pageSize"
            layout="total,prev, pager, next,sizes"
            :total="pagination1.total"
          ></el-pagination>
          <el-dialog
            :title="activeName == 'first' ? '标记无效' : '提示'"
            width="600px"
            :visible.sync="diaDetailVisible"
            :destroy-on-close="true"
            append-to-body
            :before-close="closeMarker"
          >
            <div v-if="activeName == 'first'">
              <p style="margin: 0px 15px 10px">标记无效后将不统计KPI</p>
              <el-form
                @submit.native.prevent
                label-width="100px"
                :model="form"
                ref="form"
              >
                <el-form-item
                  label="原因描述："
                  prop="comment"
                  :rules="{
                    required: true,
                    message: '请输入原因描述',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    type="textarea"
                    maxlength="200"
                    :rows="3"
                    placeholder="请输入原因描述"
                    v-model.trim="form.comment"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div v-else>
              <p style="font-size: 15px; font-weight: bold; margin: 0px 15px">
                确定恢复为有效备注？
              </p>
              <p style="margin: 10px 15px">恢复后将被统计KPI</p>
            </div>
            <span slot="footer">
              <el-button size="mini" @click="diaDetailVisible = false"
                >取 消</el-button
              >
              <el-button size="mini" type="primary" @click="saveDiaDetail"
                >确 定</el-button
              >
            </span>
          </el-dialog>
          <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        <i
          v-if="drawerTitle == '客户'"
          class="drawIcon iconfont icon-kh_xq"
        ></i>
        <i
          v-if="drawerTitle == '人才'"
          class="drawIcon iconfont icon-rc_bt"
        ></i>
        {{ drawerTitle }}
      </div>
      <div class="drawerCon" :style="drawerTitle == '人才' ? 'padding:0' : ''">
        <addoreEditCus
          v-if="drawerTitle == '编辑客户' && drawerVisible"
          @loadList="loadList"
          @tuComInfo="detailsPop"
          :customId="customId"
        ></addoreEditCus>
        <customerInfo
          v-if="drawerTitle == '客户' && drawerVisible"
          @toEdit="toEditComInfo"
          :customId="customId"
          :activeToInfo="activedToInfo"
          @loadList="loadList"
        ></customerInfo>
        <resumeDetails
          v-if="drawerTitle == '人才' && drawerVisible"
          :resumeDetailId="customId"
          :hover="hoverInfo"
          :repeatResume="repeatResume"
        ></resumeDetails>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  getKpiPlanList,
  addorEdit,
  myKpiDetailList,
  kpiItemDetail,
  getRoleBaseInfos,
  permissionTree, //小类
  permissionType, // 大类
  exportKpiDetailList,
  exportKpiDetail,
  kpiDataThrough, //KPI数透
  exportKpiDataThrough, //导出KPI数透
  qualityCheckMarkAsinValid, //标记为无效
  qualityCheckrecoverAsinValid, //恢复为有效
  detailQualifiedCount, //kpitab数量
} from "../../api/api";
import customerInfo from "./../../components/customer/customerInfo";
import addoreEditCus from "./../../components/customer/addorEditCustomer";
import resumeDetails from "../../components/resumeDetail";
import { ApiBaseUrl } from "../../api/http";
export default {
    props:{
        dialogTitle:{type:String,default:''},
        info:{type:Object}
    },
    components:{
        customerInfo,
        addoreEditCus,
        resumeDetails,
    },
    data(){
        return{
            activeName: "first",
            form: {
                id: "",
                comment: "",
                kpiItem: "",
            },
            tabData: [],
            pagination1: {
                total: 0,
                pageNo: 1,
                pageSize: 50,
            },
            iscusoption1: [],
            headerOption: [],
            kpiHeardItems: [],
            iscusoption: [],
            offeroption: [],
            dialogVisible: false,
            offer: false,
            iscus: false,
            drawerVisible: false,
            drawerTitle: "",
            customId: null,
            repeatResume: { type: "列表", valId: "" },
            hoverInfo: { matchAll: true, majorSearch: false, hover: true },
            activedToInfo: "1",
            isData: false,
            diaDetailVisible:false,
            isRemark:false,
            havePermissions: false,
            baseUrl: ApiBaseUrl,
        }
    },
    created(){
        let permTypes = this.getStore("permiss");
        if (permTypes.includes("record:KpiData:qualityCheckMark")) {
      this.havePermissions = true;
    }
        this.dialogVisibletrue(this.info)
    },
    methods:{
         // 弹框
        dialogVisibletrue(val) {
            this.activeName = "first";
            this.clickGetName = val.item;
            this.dialogTitle = val.item;
            this.pagination1.pageNo = 1;
            this.listVal = val;
            this.kpiItemDetail().then(() => {
                if (
                val.item == "候选人备注" ||
                val.item == "coldcall备注" ||
                val.item == "内部面试" ||
                val.item == "F2F面试" ||
                val.item == "客户沟通"
                ) {
                this.isRemark = true;
                } else {
                this.isRemark = false;
                }
            });

            this.dialogVisible = true;
            if (val.item == "Offer") {
                this.offer = true;
            } else if (val.item == "候选人备注") {
                this.iscus = true;
            } else if (val.item == "内部面试") {
                this.iscus = true;
            }
        },
        handleClick(val) {
            this.pagination1.pageSize = 50;
            this.pagination1.pageNo = 1;
            this.kpiItemDetail();
        },
        exportExl() {
            let setData = false;
            let isconfirm = true;
            var int = setTimeout(function () {
                let that = this;
                setData = true;
                clearInterval(int);
                let div =
                "<p style='font-weight:bold'>后台处理中，稍后请在 " +
                "<a href='/tools/exportingRecords' style='color:#526ecc'>导出记录</a>" +
                " 获取文件</p>";
                if (isconfirm) {
                that.$globalHub
                    .$confirm(div, "提示", {
                    showCancelButton: false,
                    showConfirmButton: false,
                    customClass: "altCls",
                    dangerouslyUseHTMLString: true,
                    })
                    .then(() => {})
                    .catch(() => {});
                }
            }, 5000);
            //导出
            exportKpiDetail(this.exportKpiType).then((res) => {
                if (res.success) {
                if (!setData) {
                    isconfirm = false;
                    location.href = this.baseUrl + res.result;
                }
                }
            });
        },
        // 弹出框数据
        kpiItemDetail() {
            $(".diaDiv").scrollTop(0);
            return new Promise((resolve) => {
                const obj = {};
                obj.startDate = this.listVal.startDate;
                obj.endDate = this.listVal.endDate;
                obj.sourceId = this.listVal.sourceId;
                obj.item = this.listVal.item;
                obj.current = this.pagination1.pageNo;
                obj.size = this.pagination1.pageSize;
                obj.type = this.listVal.sourceType;
                obj.companyId = this.listVal.companyId;
                obj.qualified = this.activeName == "first" ? true : false;
                this.exportKpiType = obj;
                kpiItemDetail(obj).then((res) => {
                if (res.success) {
                    this.detailData = res.result;
                    if (res.result.qualityCheck) {
                    this.getQualifiedCount();
                    }
                    this.headerOption = res.result.headers;
                    this.pagination1.total = res.result.total;
                    this.pagination1.pageSize = res.result.size;
                    this.iscusoption1 = res.result.data;

                    if (this.iscusoption1.length < 1) {
                    //暂无数据
                    this.isData = true;
                    } else {
                    this.isData = false;
                    }
                }
                resolve();
                });
            });
        },
        getQualifiedCount() {
            let obj = {};
            obj.startDate = this.listVal.startDate;
            obj.endDate = this.listVal.endDate;
            obj.sourceId = this.listVal.sourceId;
            obj.item = this.listVal.item;
            obj.type = this.listVal.sourceType;
            obj.companyId = this.listVal.companyId;
            detailQualifiedCount(obj).then((res) => {
                if (res.success) {
                this.tabData = res.result;
                }
            });
        },
        handleSizeChange1(val) {
            //pageSize 改变时会触发
            this.pagination1.pageSize = val;
            this.pagination1.pageNo = 1;
            this.kpiItemDetail();
        },
        handleCurrentChange1(val) {
            //currentPage 改变时会触发
            this.pagination1.pageNo = val;
            this.kpiItemDetail();
        },
        goNav(router) {
            const arr = JSON.parse(router);
            if (Array.isArray(arr)) {
                if (!router.includes("commentColumnId")) {
                return arr.forEach((a) => {
                    let _router = this.$router.resolve({
                    path: "/resume/details",
                    query: { id: a.relativeId },
                    });
                    window.open(_router.href, "_blank");
                });
                }
            }
        },
        goUrl(url, index) {
            try {
                if (
                [
                    "简历推荐",
                    "加入项目",
                    "内部面试",
                    "客户面试_全部",
                    "客户面试_初",
                    "客户面试_2",
                    "客户面试_3及以上",
                    "客户面试_终",
                    "Offer",
                    "失败Offer",
                    "项目BD",
                    "F2F面试",
                    "拜访客户",
                    "入职",
                    "人才_新增",
                    "人才_插件导入",
                    "人才_上传",
                    "人才_上传List",
                    "人才_全部",
                    "BD线索",
                    "BD主导",
                ].includes(this.clickGetName)
                ) {
                const parseUrl = JSON.parse(url);
                const headerText = this.headerOption[index];
                if (headerText === "候选人") {
                    this.drawerTitle = "人才";
                    this.drawerVisible = true;
                    this.customId = parseUrl.relativeId;
                } else if (headerText === "项目名称") {
                    let _router = this.$router.resolve({
                    path: "/project/details",
                    query: { id: parseUrl.relativeId },
                    });
                    window.open(_router.href, "_blank");
                } else if (headerText === "客户名称") {
                    this.drawerTitle = "客户";
                    this.drawerVisible = true;
                    this.customId = parseUrl.relativeId;
                }
                }
            } catch (e) {
                return;
            }
        },

        /**
         * 提取json中name字段 不是json返回原值
         * @example renderIE('2020-02-03 15:52:20'); // => 2020-02-03 15:52:20
         * @example renderIE('{"name": "移动测试-移动测试","relativeId": "12"}'); // => 移动测试-移动测试
         * @param ie {string}
         * @returns {string}
         */
        renderIE(ie) {
            if (ie) {
                let _ie;
                try {
                _ie = JSON.parse(ie);
                } catch (e) {
                return ie;
                }
                if (/^\d+$/.test(_ie)) {
                return _ie;
                }
                if (_ie.name) {
                return `<a class="aStyle">${_ie.name}</a>`;
                }
            }
        },
        handleClose(done) {
            done();
        },
        nativalNav(i, index) {
            const headerText = this.headerOption[index];
            // debugger
            try {
                let _da = JSON.parse(i);
                if (headerText === "候选人") {
                this.drawerTitle = "人才";
                this.drawerVisible = true;
                this.customId = _da.relativeId;
                } else if (headerText === "项目名称") {
                let _router = this.$router.resolve({
                    path: "/project/details",
                    query: { id: _da.relativeId },
                });
                window.open(_router.href, "_blank");
                } else if (headerText === "客户名称") {
                this.drawerTitle = "客户";
                this.drawerVisible = true;
                this.customId = _da.relativeId;
                }
            } catch (e) {
                return;
            }
        },
        internalM(i, index) {
            let _ie;
            try {
                _ie = JSON.parse(i);
            } catch (e) {
                return i;
            }
            if (Array.isArray(_ie)) {
                return;
            }
            if (_ie.name) {
                return `<a class="aStyle">${_ie.name}</a>`;
            } else {
                return _ie;
            }
        },
        serializeIE(ie) {
        //备注详情字段处理
            try {
                const arr = JSON.parse(ie);
                let str = [];
                if (Array.isArray(arr)) {
                if (ie.includes("commentColumnId")) {
                    str = arr.filter((a) => {
                    return a.value;
                    });
                    return str
                    .map(
                        (a) =>
                        `${a.columnTitle == "详情" ? "" : a.columnTitle + "："}${
                            a.value
                        }`
                    )
                    .join("\n");
                } else {
                    return arr.map((a) => `${a.name}`).join("\n");
                }
                } else {
                // 不是数组就走catch
                throw 1;
                }
            } catch (e) {
                return ie;
            }
        },
        //详情表格操作
        changeStatus(data) {
            this.diaDetailVisible = true;
            this.form.id = data.id;
            this.form.kpiItem = this.dialogTitle;
        },
        //详情表格操作确定
        saveDiaDetail() {
        if (this.activeName == "first") {
            this.$refs.form.validate((valid) => {
            if (valid) {
                qualityCheckMarkAsinValid(this.form).then((res) => {
                if (res.success) {
                    this.closeMarker();
                    this.kpiItemDetail();
                }
                });
            }
            });
        } else {
            qualityCheckrecoverAsinValid(this.form).then((res) => {
            if (res.success) {
                this.closeMarker();
                this.kpiItemDetail();
            }
            });
        }
        },
        closeMarker() {
            this.diaDetailVisible = false;
            this.form.comment = "";
        },
    }
}
</script>

<style scoped lang="scss">
.fl :deep(.el-form-item__content) {
    display: flex;
    align-items: center;
  }
.tableTdTrH {
  line-height: 36px;
  height: 36px;
  font-weight: bold;
  background: #f5f7fa;
}
.tableTyTrH {
  height: auto;
  td:first-child,
  th:first-child,
  td:nth-child(2),
  th:nth-child(2) {
    background-color: #fff;
  }
}
.fixedLeft {
  td:first-child,
  th:first-child,
  td:nth-child(2),
  th:nth-child(2) {
    position: sticky;
    left: 0; /* 首行永远固定在左侧 */
    z-index: 4;
  }
}
.hasShadow {
  td:nth-child(2),
  th:nth-child(2) {
    left: 120px;
    box-shadow: 5px 0 10px -5px #ddd;
  }
}
.is_fixed {
  position: fixed;
  top: 44px;
  z-index: 9;
}
.kpiSet {
  height: 28px;
  /*padding: 10px 15px;*/
  border: 1px solid #ededed;
  width: 85px;
  line-height: 28px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  box-sizing: border-box;
  /* margin-left: 15px; */
  display: block;
  float: left;
  color: #606266;
  i {
    /*color: rgb(187, 187, 187);*/
    font-size: 14px;
  }

  &:hover {
    color: #333333;
    border: 1px solid #999999 !important;
  }
}

pre {
  font-family: "Microsoft YaHei", Arial, Helvetica, sans-serif;
  white-space: pre-line;
}

table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
thead {
  // border-top: 1px solid #ebeef5;
  // border-bottom: 1px solid #ebeef5;
}
thead,
tbody {
  display: block;
}

th {
  padding: 0;
  /*flex: 1;*/
  background-color: rgb(245, 247, 250);
  font-weight: normal;
}

tr {
  height: 50px;
  line-height: 50px;
  /*display: flex;*/
}

td {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  white-space: nowrap;
}

td,
th {
  display: inline-block;
  width: 150px;
  /*flex: 1;*/
  padding: 0 15px;
}

.digTable tr {
  display: flex;
}

.digTable th,
.digTable td {
  flex: 1;
  /*width: 250px !important;*/
}

.iconshaixuan:hover {
  color: #ff3f3f;
  cursor: pointer;
}

.specialS td {
  // overflow: auto;
  white-space: normal;
}
</style>